import { trigger, transition, style, animate, query, stagger } from '@angular/animations';

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter', [
      style({ opacity: 0 }),
      stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))
    ], {
      optional: true
    })
  ])
]);

const pageAnimation = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
  ]),
  transition(':leave', [animate('300ms ease-in', style({ transform: 'translateX(100%)' }))])
]);

export { listAnimation, pageAnimation };
